import { BrowserRouter, Routes, Route } from "react-router-dom";
import EmployeeMumbaiBrands from "./pages/EmployeeMumbaiBrands";
import EmployeeAhmedabadBrands from "./pages/EmployeeAhmedabadBrands";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/mumbai" element={<EmployeeMumbaiBrands />} />
          <Route path="/ahmedabad" element={<EmployeeAhmedabadBrands />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
