import React from "react";
import { Grid, Link, Box } from "@mui/material";
import BigFatMonk from "../images/Big fat monk.jpg";
import Kbobs from "../images/Kbobs Logo.jpg";
import MomoGuy from "../images/Momo guy Logo.jpg";
import NewYourkEaffles from "../images/New York Eaffles & Dinges.jpg";
import QuickCombo from "../images/Quick Combos Logo.jpg";
import SpeakBurgers from "../images/Speak Burgers.jpg";
import BlackChimney from "../images/The Black Chimney Logo.jpg";
import WackWaffles from "../images/Wack Waffles Brownies Logo.jpg";
import WakkaMakka from "../images/Wakka Makka.jpg";
import WinnerPizza from "../images/Winner Pizza Logo.jpg";
import StarBoyPizza from "../images/Starboy Pizza & Shakes.jpg";

const logos = [
  {
    src: Kbobs,
    alt: "Kbobs Logo",
    link: "https://link.zomato.com/xqzv/rshare?id=7105669330563a4e",
  },
  {
    src: NewYourkEaffles,
    alt: "New York Eaffles & Dinges",
    link: "https://link.zomato.com/xqzv/rshare?id=7105667230563e01",
  },
  {
    src: BlackChimney,
    alt: "The Black Chimney Logo",
    link: "https://link.zomato.com/xqzv/rshare?id=7105670530563fe4",
  },
  {
    src: WinnerPizza,
    alt: "Winner Pizza Logo",
    link: "https://link.zomato.com/xqzv/rshare?id=7138269430563b86",
  },
  {
    src: StarBoyPizza,
    alt: "Starboy Pizza & Shakes",
    link: "https://link.zomato.com/xqzv/rshare?id=7105663830563f86",
  },
];

const EmployeeAhmedabadBrands = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          border: "solid .5mm",
          padding: "26px",
          borderRadius: "16px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: "calc(50% - 170px)",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "9px",
            borderRadius: "33px",
            border: "solid .5mm",
            display: "inline-block",
            marginBottom: "22px",
          }}
        >
          <div> Explore our other brands below </div>
        </Box>
        <div style={{ maxWidth: "800px" }}>
          <Grid container spacing={8} justifyContent="center">
            {logos.map((logo, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link
                  href={logo.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    style={{
                      maxWidth: "100%",
                      height: "300px",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default EmployeeAhmedabadBrands;
